<template>
  <div id="create-advanced-rule" class="m-1">
    <div v-if="loadingSpinner" class="loading-spinner">
      <b-spinner variant="primary"/>
    </div>
    <b-card :style="`min-height: ${screenHeight}`" v-if="mySession.id && ((!loading[`${entity.split('_id')[0]}Rule`] && !loading.ruleTypes) || !!isNewRule)">
      <div class="m-1">
        <h3 class="mb-2" v-if="!form.id">{{ $t('Nueva Regla') }}</h3>
        <h3 class="mb-2" v-else >{{ $t('Edición de Regla') }}</h3>
        <form-render :form.sync="form" :key="keyFormRender" :fields="fieldsRule" ref="formRenderRuleData">
          <template #activeRuleSlot>
            <div>
              <b-form-checkbox class="custom-switch-success" :class="!watchers.editableRule || !!isNewRule ? 'disable-switch' : ''" :checked="form.active" switch v-model="form.active" :disabled="!watchers.editableRule || !!isNewRule" @change="activateRule(form)"> {{$t(form.active ? 'Activa' : 'Inactiva')}}</b-form-checkbox>
            </div>
          </template>
        </form-render>
      </div>
      <rule-conditions v-for="(condition, index) in conditions" :key="`${index}-${(index + keyCondition) * keyCondition}`"
        :condition="condition" :conditions.sync="conditions" :carriers.sync="carriers" :conditionIndex="index"
        :country.sync="country" :entity.sync="entity" :entity_id.sync="entity_id" :ruleTypes="ruleTypes" :outputTypes="outputTypes" :disables="watchers"
        @remove="deleteCondition" @update="updateCondition" @updateOutput="updateConditionOutput" @watchers="emitWatcher" >
      </rule-conditions>
      <div class="pb-2">
        <b-button variant="outline-warning" class="ml-2 pl-2 pr-2 push-left" v-show="watchers.editableRule" :disabled="disabledToIncreaseCondition"
          v-if="conditions && conditions.length !== 0 && ((conditions[0].order === 0 && form.id) || (conditions[conditions.length-1].settings && (conditions[conditions.length-1].settings.length !== 0)))" 
          @click="increaseCondition(condition)"> 
          Agregar condición
        </b-button>
        <b-button v-if="!form.id" :disabled="watchers.disabledCreateButton" variant="outline-success" :class="watchers.disabledCreateButton ? 'cursor-not-allowed' : ''" class="mr-2 pl-4 pr-4 push-right" @click="saveRule()"> Crear regla </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import RuleConditions from './RuleConditions.vue'
import Rule from './rule'
import RuleServices from '@/views/modules/advanced-rules/advancedRules.service'
import BaseServices from '@/store/services'
export default {
  name: '',
  components: { RuleConditions },
  data() {
    return {
      isNewRule: false,
      loadingSpinner: true,
      screenHeight: `${screen.height - 250}px`,
      carriers: [],
      ruleTypes: [],
      outputTypes: [],
      nameRule: '',
      form: {},
      condition: {},
      updatedData: {},
      country: {},
      fieldsRule: [],
      idToCarrier: {},
      keyFormRender: 0,
      keyCondition: 0,
      conditionDefault: [{
        id: 0,
        order: 0,
        output: null,
        settings: []
      }],
      watchers: {
        disabledAdd: false,
        disabledCard: true,
        editableRule: false,
        disabledCreateButton: true
      },
      loading: {
        levels: true,
        ruleTypes: true,
        carriers: true,
        organizations: true,
        shipperRule: true,
        organizationRule: true,
        globalData: true
      },
      ruleService: new RuleServices(this),
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      generalLoading: 'getLoading',
      globalData: 'getGlobalData',
      organization: 'getOrganization',
      organizations: 'getOrganizationsGlobal',
      shippersByOrganization: 'getShippersByOrganizationSimplified',
      levels: 'getLevels',
      shipperRule: 'getAdvancedRule',
      organizationRule: 'getAdvancedRuleByOrganization'
    }
    ),
    conditions: {
      get() {
        return this.form.conditions
      },
      set(conditions) {
        return this.form.conditions = conditions
      }
    },
    serviceChangeLocation() {
      return this.entity === 'shipper_id' ? 'ChangeValueCode' : 'ChangeValueCodeByOrganization'
    },
    disabledToIncreaseCondition() {
      return this.watchers.editingRule || !(Array.isArray(this.form?.conditions[this.form.conditions.length - 1]?.settings) && this.form?.conditions[this.form.conditions.length - 1]?.settings?.length !== 0)
    }
  },
  watch: {
    'generalLoading': {
      handler() {
        this.loading.shipperRule = !!this.generalLoading.getAdvancedRule
        this.loading.organizationRule = !!this.generalLoading.getAdvancedRuleByOrganization
        this.loading.levels = !!this.generalLoading.getLevels
        this.loading.organizations = !!this.generalLoading.getOrganizationsGlobal
        this.loading.globalData = !!this.generalLoading.getGlobalData
      },
      deep: true
    },
    'loading': {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        // Que la actualización del componente solo se fuerce cuando esté todo cargado
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    },
    mySession () {
      this.setSession()
    },
    shipperRule () {
      this.setRule(this.shipperRule)
    },
    organizationRule () {
      this.setRule(this.organizationRule)
    },
    // organization () {
    //   this.country = this.globalData.countries.find(el => el.id === this.organization.country.id) || null
    // },
    // shipper () {
    //   this.country = this.globalData.countries.find(el => el.id === this.shipper.country.id) || null
    // },
    organizations () {
      this.setSelectOptions(this.fieldsRule, 'organization', { options: this.organizations })
    },
    shippersByOrganization () {
      this.setSelectOptions(this.fieldsRule, 'shipper', { options: this.shippersByOrganization })
    }
  },
  mounted() {
    this.entity = this.$route.params.entity === 'organization' ? 'organization_id' : 'shipper_id'
    this.entity_id = this.$route.params.entity_id === 'null' ? null : parseInt(this.$route.params.entity_id)
    this.rule_id = this.$route.params.rule_id === 'null' ? null : parseInt(this.$route.params.rule_id)
    this.advanceRuleService = this.entity === 'shipper_id' ? 'AdvancedRule' : 'AdvancedRuleByOrganization'
    this.settingRuleService = this.entity === 'shipper_id' ? 'SettingRule' : 'SettingRuleByOrganization'
    this.settingUploadFileService = this.entity === 'shipper_id' ? 'UploadFileLocation' : 'UploadFileLocationByOrganization'
    if (this.entity_id === null || this.rule_id === null) { 
      this.isNewRule = true
      this.watchers.disabledCard = false
    }
    this.setSession()
  },
  methods: {
    setSession() {
      if (this.mySession.id) {
        if (this.rule_id !== null) {
          const params = {
            id: this.rule_id,
            [this.entity]: this.entity_id
          }
          this.getRule(params)
        } else {
          this.setRule()
        }
      }
    },
    setRule(rule) {
      if (!!rule?.id) {
        this.form = { ...rule }
        this.getAditionalInfo(rule)
      } else {
        this.form = { ...this.form, conditions: this.conditionDefault }
      }
      this.setInitialData()
    },
    setInitialData() {
      // this.watchers.disabledCard = this.editableRule()
      this.watchers.editableRule = this.editableRule()
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
      // if (['marketplace'].includes(this.$session.get('cas_user').role)) {
        this.fieldsRule = [
          { name: 'activeRuleSlot', useSlot: true, containerClass: 'container-info col-sm-2 col-md-2' },
          {name: 'line0', useSlot: true, skipLine: true, containerClass: 'col-sm-10 col-md-10 col-lg-10 container-info' },
          { fieldType: 'FieldSelect', name: 'organization', label: 'Organización', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-3 container-info mb-2', change: this.changeOrganization},
          { fieldType: 'FieldInput', name: 'name', label: 'Nombre de la Regla', containerClass: 'container-info col-sm-12 col-md-3 col-lg-3', validation: 'required'}
        ]
        if (this.entity === 'shipper_id') {
          this.fieldsRule.splice((this.getElementIndex(this.fieldsRule, 'organization', 'name') + 1), 0, 
            { fieldType: 'FieldSelect', name: 'shipper', label: 'Empresa', validation: 'required', useLabel: true, containerClass: 'col-sm-12 col-md-3 col-lg-3 container-info mb-2', dependency: 'organization', change: this.changeShipper },
            { name: 'line0', useSlot: true, useSkeleton: true, skipLine: true, containerClass: 'container-info col-sm-12 col-md-6 col-lg-6 mb-2' }
          )
        }
        if (!!this.organizations && this.organizations.length !== 0) { 
          this.setSelectOptions(this.fieldsRule, 'organization', { options: this.organizations })
        }
      // } else if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
      } else if (['marketplace'].includes(this.$session.get('cas_user').role)) {
        this.fieldsRule = [
          { name: 'activeRuleSlot', useSlot: true, containerClass: 'container-info col-sm-12 col-md-12' },
          { fieldType: 'FieldSelect', name: 'organization', label: 'Marketplace', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-3 container-info mb-2', disabled: true },
          { fieldType: 'FieldInput', name: 'name', label: 'Nombre de la Regla', containerClass: 'container-info col-sm-12 col-md-3 col-lg-3', validation: 'required'}
        ]
        if (this.entity === 'shipper_id') {
          this.fieldsRule.splice((this.getElementIndex(this.fieldsRule, 'organization', 'name') + 1), 0, 
            { fieldType: 'FieldSelect', name: 'shipper', label: 'Seller / Ecommerce', validation: 'required', useLabel: true, containerClass: 'col-sm-12 col-md-3 col-lg-3 container-info mb-2', dependency: 'organization', change: this.changeShipper },
            { name: 'line0', useSlot: true, useSkeleton: true, skipLine: true, containerClass: 'container-info col-sm-12 col-md-6 col-lg-6 mb-2' }
          )
        }
        if (!this.form?.id) this.form.organization = this.$session.get('cas_user').organization
        this.changeOrganization('mkp', this.form.organization, this.form.shipper)
      } else if (['ecommerce'].includes(this.$session.get('cas_user').role)) {
        this.fieldsRule = [
          { name: 'activeRuleSlot', useSlot: true, containerClass: 'container-info col-sm-12 col-md-12' },
          { fieldType: 'FieldSelect', name: 'shipper', label: 'Empresa', validation: 'required', useLabel: true, containerClass: 'col-sm-12 col-md-3 col-lg-3 container-info mb-2', disabled: true },
          { fieldType: 'FieldInput', name: 'name', label: 'Nombre de la Regla', containerClass: 'container-info col-sm-12 col-md-3 col-lg-3', validation: 'required'}
        ]
        this.form.shipper = { id: this.$session.get('cas_user').shipper.id, text: this.$session.get('cas_user').shipper.name1 }
        this.changeShipper('shipper', this.form.shipper)
      }
      this.form.active = this.form.active ? this.form.active : false
      if (this.form.id) {
        this.fieldsRule.map(field => ['name', 'organization', 'shipper'].includes(field.name) ? field.disabled = true : field)
      } 
      this.environment = this.ruleService.setEnvironment()
      if (this.isNewRule) this.loadingSpinner = false
    },
    getAditionalInfo(rule) {
      if (!rule.owner.shipper && rule.organization_id) {
        this.getCarriers({ organization_id: rule.organization_id })
      }
      if (rule.owner.shipper && rule.shipper_id) {
        this.getCarriers({ shipper_id: rule.shipper_id })
      }
      const countryRule = rule.owner.shipper ? this.globalData.countries.find(el => el.code.toLowerCase() === rule.shipper.country_code.toLowerCase()) : this.globalData.countries.find(el => el.code.toLowerCase() === rule.organization.country_code.toLowerCase()) || null
      this.country = { ...countryRule }
      const services = []
      this.getRuleTypes({ [this.entity]: this.entity_id })
      if (!['mx'].includes(this.country.code.toLowerCase())) {
        services.push({ name: 'getLevels', params: {country: this.country.code.toLowerCase()} })
      }
      if (services.length !== 0) this.$store.dispatch('fetchMultipleServices', {services})
    },
    changeOrganization (name, value, shipper = null) {
      this.form = {
        ...this.form,
        shipper: shipper ? shipper : null
      }
      const index = this.fieldsRule.findIndex(el => el.name === 'shipper')
      if (index !== -1) this.fieldsRule[index].options = []
      const idToCarrier = this.form?.organization?.id ? { organization_id: this.form?.organization?.id } : null
      if (!value?.id) {
        return
      }
      const services = []
      if (this.entity === 'organization_id') { 
        const params = { organization_id: value.id }
        this.getRuleTypes(params)
        this.getCarriers(params)
      }
      else if ((this.entity === 'shipper_id')) services.push({ name: 'getShippersByOrganizationSimplified', params: { organization_id: !!value ? value.id : this.form.organization?.id || 0 }, queryParams: { page: 1, paginate_by: 99999 } })
      if (index !== -1) this.fieldsRule[index].useSkeleton = true
      if (services.length !== 0) this.$store.dispatch('fetchMultipleServices', { services, 
        onSuccess: () => { if (this.entity !== 'shipper_id') this.getCarriers(idToCarrier); if (index !== -1) this.fieldsRule[index].useSkeleton = false },
        onError: () => { if (index !== -1) this.fieldsRule[index].useSkeleton = false }
      })
    },
    changeShipper (name, value) {
      if (value === null) return
      const params = { shipper_id: value.id }
      this.getRuleTypes(params)
      this.getCarriers(params)
    },
    setSelectOptions (fields, name, { options }) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) {
        fields[index].options = options
        this.keyFormRender++
      }
    },
    getCarriers(id) {
      let service = 'getCarriersByOrganization'
      if (Object.entries(id)[0][0] === 'shipper_id') service = 'getCarriersByShipper'
      this.ruleService.callService(service, {}, id)
        .then(resp => {
          this.carriers = Object.keys(resp.data.associated_carriers).map((row) => {
            return {
              ...resp.data.associated_carriers[row],
              text: resp.data.associated_carriers[row].carrier_name,
              id: resp.data.associated_carriers[row].carrier_id,
              code: resp.data.associated_carriers[row].carrier_code
            }
          })
          this.loading.carriers = true
        })
        .catch(err => {
          console.error(err)
        })   
    },
    getElementIndex(array, value, property) {
      return array.findIndex(field => field[property] === value)
    },
    clearInput(form, fields, name) {
      delete form[name]
      fields[this.getElementIndex(fields, name, 'name')].options = []
    },
    increaseCondition() {
      const data = {
        name: `post${this.advanceRuleService}Condition`,
        params: {
          rule_id: this.rule_id,
          [this.entity]: this.entity_id
        },
        queryParams: {
          rule_id: this.rule_id,
          draft_status: false,
          name: '',
          order: Math.max(...this.conditions.map(condition => condition.order)) + 1
        },
        onSuccess: (data) => { 
          this.form.conditions.push(
            { 
              isNewCondition: true,
              draft_status: data.data.draft_status, 
              id: data.data.id, 
              name: data.data.name, 
              order: data.data.order, 
              output: data.data.output, 
              rule_id: data.data.rule_id, 
              settings: data.data.settings
            }
          ); this.loadingSpinner = false 
        }
      }
      this.loadingSpinner = true
      this.$store.dispatch('fetchService', data)
    },
    deleteCondition (_id) {
      const data = {
        name: `delete${this.advanceRuleService}Condition`,
        params: {
          rule_id: this.rule_id,
          [this.entity]: this.entity_id,
          condition_id: _id
        },
        onSuccess: () =>  { this.form.conditions.splice(this.getElementIndex(this.form.conditions, _id, 'id'), 1); this.loadingSpinner = false }
      }
      this.loadingSpinner = true
      this.$store.dispatch('fetchService', data)
    },
    updateConditionOutput (condition, condition_index)  {
      this.form.conditions[condition_index] = condition
      this.conditions = this.form.conditions
    },
    updateCondition (condition, condition_id, condition_index) {
      this.updateConditionOutput({ ...condition, id: condition_id }, condition_index)
      const { settings, settingWithPlaces } = this.setPlacesFromSettingsOnCondition(condition.settings)
      const data = { 
        name: `update${this.advanceRuleService}Condition`,
        params: {
          rule_id: this.rule_id,
          [this.entity]: this.entity_id,
          condition_id
        },
        queryParams: {
          ...condition,
          settings,
          rule_id: this.rule_id
        },
        onSuccess: (resp) =>  { this.updateConditionSavePlaces(resp, condition_index, settingWithPlaces); this.emitWatcher({ editingRule: false }) },
        onError: (error) => { this.loadingSpinner = false; this.$alert(this.$t('msg-problem-save-rule', {code: error})) } 
      }
      this.loadingSpinner = true
      this.$store.dispatch('fetchService', data)
    },
    updateConditionSavePlaces (conditionUpdated, condition_index,  settings) {
      if (Array.isArray(settings) && settings.length !== 0) {
        this.savePlacesOfSetting(settings, conditionUpdated.data, condition_index)
      } else {
        this.updatedData[`updatedCondition_${condition_index}`] = conditionUpdated.data
        this.setUpdatedCondition(conditionUpdated.data, condition_index)
      }
    },
    setUpdatedCondition(updatedCondition, condition_index) {
      this.form.conditions[condition_index] = updatedCondition
      this.conditions = this.form.conditions
      this.loadingSpinner = false
      this.keyFormRender++
    },
    savePlacesOfSetting(settings, updatedCondition, condition_index) {
      this.updatedData[`updatedCondition_${condition_index}`] = updatedCondition
      let loadingUploadFile = true
      let loadingServices = true
      const settingsIds = this.getIdsFromSettings(updatedCondition)
      const services = []
      settings.map((setting, index) => {
        const service = {
          name: setting.nameService,
          queryParams: setting.payload
        }
        if (service.name === `post${this.settingUploadFileService}`) {
          loadingUploadFile = false
          service.params = {[this.entity]: this.entity_id }
          service.queryParams = { ...service.queryParams, rule_setting_id: settingsIds[setting.payload.rule_type_id]}
          this.baseService.callUploadFileCustomParams(service.name, service.queryParams, service.params)
            .then(resp => {
              this.updatedData[resp.data.rule_type_id] = { count_rule_setting_input: resp.data.count_rule_setting_input }
              if ((settings.length - 1) === index) {
                this.setUpdatedCondition(this.updatedData[`updatedCondition_${condition_index}`], condition_index)
              }
            })
            .catch((err) => this.$alert(this.$t('msg-problema-cargar-datos', {code: err.message})))
            .finally(() => {
              loadingUploadFile = true
              if (loadingServices) this.loadingSpinner = false
            })
        } else if (service.name === `post${this.serviceChangeLocation}` || service.name === `delete${this.serviceChangeLocation}`) {
          service.params = {[this.entity]: this.entity_id}
          service.queryParams = { input: setting.payload.input, rule_setting_id: settingsIds[setting.payload.rule_type_id] }
          services.push(service)
          loadingServices = false
        } else {
          service.params = {[this.entity]: this.entity_id, id: settingsIds[setting.payload.rule_type_id] }
          services.push(service)
          loadingServices = false
        }
      })
      if (services.length !== 0) {
        this.$store.dispatch('fetchMultipleServices', { services, onSuccess: (data) => { 
          loadingServices = true 
          if (loadingUploadFile) this.loadingSpinner = false
          data.map(call => {
            this.updatedData[`updatedCondition_${condition_index}`].settings.map((setting, index) => { if (setting.id === call.data.id) this.updatedData[`updatedCondition_${condition_index}`].settings[index] = call.data })
          })
          this.setUpdatedCondition(this.updatedData[`updatedCondition_${condition_index}`], condition_index)
        }, 
        onError: (error) => { 
          this.$alert(this.$t('msg-problem-save-rule', {code: error}), this.getRule({ id: this.rule_id, [this.entity]: this.entity_id })) 
        }
        })
      } else if (loadingUploadFile) this.loadingSpinner = false
    },
    setPlacesFromSettingsOnCondition(settings) {
      const settingWithPlaces = []
      settings = settings.map(setting => {
        if ([3, 8].includes(setting.rule_type_id)) {
          if (setting.updatePlaces) { 
            settingWithPlaces.push({
              payload: {
                rule_id: this.rule_id,
                rule_type_id: setting.rule_type_id,
                input: setting.input
              },
              nameService: this.entity === 'shipper_id' ? 'updateSettingRule' : 'updateSettingRuleByOrganization' 
            })
          } else if (setting.uploadFile) {
            settingWithPlaces.push({ 
              payload: {
                rule_id: this.rule_id,
                rule_type_id: setting.rule_type_id,
                input: setting.input,
                to: this.$session.get('cas_user').email
              },
              nameService: `post${this.settingUploadFileService}`
            })
          } else if (setting.updatePlacesMx) {
            const placesToPost = setting.input.filter(place => place.action === 'post').map(place => { return { code: place.code } })
            const placesToDelete = setting.input.filter(place => place.action === 'delete').map(place => { return { code: place.code } })
            const services = []
            if (Array.isArray(placesToPost) && placesToPost.length !== 0) services.push({ name: 'post', places: placesToPost })
            if (Array.isArray(placesToDelete) && placesToDelete.length !== 0) placesToDelete.map(place => services.push({ name: 'delete', places: place}))
            services.map(service => {
              settingWithPlaces.push({
                payload: {
                  rule_id: this.rule_id,
                  rule_type_id: setting.rule_type_id,
                  input: service.places
                },
                nameService: `${service.name}${this.serviceChangeLocation}`
              })
            })
          }
          delete setting.updatePlaces
          delete setting.uploadFile
          delete setting.updatePlacesMx
          return {
            ...setting,
            input: []
          }
        } else return setting
      })
      return { settings, settingWithPlaces }
    },
    getIdsFromSettings (condition) {
      const ids = {}
      condition.settings.map(setting => {
        ids[setting.rule_type_id] = setting.id
      })
      return ids
    },
    saveRule () {
      const rule = this.form?.id ? this.formatPayloadToSave(this.form) : this.formatPayloadToCreate(this.form)
      const data = {
        name: rule.id ? `update${this.advanceRuleService}` : `post${this.advanceRuleService}`,
        params: { [this.entity]: this.form[this.entity.split('_id')[0]].id },
        queryParams: rule,
        onSuccess: (resp) => { 
          window.parent.location.href = `${this.environment}/advanced-rules/${this.entity.split('_id')[0]}/${resp.data[this.entity]}/${resp.data.id}`
        },
        onError: (error) => { this.$alert(this.$t('msg-problem-save-rule', {code: error})); this.loadingSpinner = false }
      }
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role) && this.entity === 'shipper_id') {
        data.name = rule.id ? 'updateAdvancedRuleByOrganizationToShipper' : 'postAdvancedRuleByOrganizationToShipper'
        data.params = {
          organization_id: this.form.organization.id,
          shipper_id: this.form.shipper.id
        }
        data.onSuccess = (resp) => window.parent.location.href = `${this.environment}/advanced-rules/${this.entity.split('_id')[0]}/${resp.data[this.entity]}/${resp.data.id}`
      }
      this.loadingSpinner = true
      this.$store.dispatch('fetchService', data)
    },
    createRule() {
      const rule = this.formatPayloadToCreate(this.form)
    },
    getRule(params) {
      const data = {
        name: `get${this.advanceRuleService}`,
        params,
        onError: () => this.loadingSpinner = false
      }
      this.$store.dispatch('fetchService', data)
    },
    formatPayloadToCreate (rule) {
      return {
        name: rule.name,
        active: rule.active,
        conditions: rule.conditions
      }
    },
    formatPayloadToSave (rule) {
      return {
        id: rule.id,
        name: rule.name || '',
        active: rule.active,
        owner: rule.owner || null,
        organization_id: rule.organization_id,
        organization: { 
          id: rule.organization.id,
          text: rule.organization.text
        },
        shipper_id: rule.shipper_id ? rule.shipper_id : null,
        shipper: { 
          id: rule.shipper?.id || null,
          text: rule.shipper?.text || null
        },
        conditions: rule.conditions
      }
    },
    emitWatcher (values) {
      Object.keys(values).map(key => this.watchers = { ...this.watchers, [key]: values[key]})
    },
    getRuleTypes(queryParams) {
      const services = [
        { name: 'getRuleTypes', queryParams: { ...queryParams, full: true } },
        { name: 'getRuleTypes', queryParams },
        { name: `getOutputTypes${this.capitalized(Object.keys(queryParams)[0].split('_id')[0])}`, params: queryParams }
      ]
      this.ruleService.callMultipleServices(services)
        .then(resp => {
          this.ruleTypes = resp[0].data.map(type => {
            return { 
              ...type,
              text: type.name,
              logic_operators: type.logic_operators !== null ? type.logic_operators.filter(operator => ['=>', '<=', '==', '!=', 'between'].includes(operator)).map(operator => this.formatLogicOperator(operator)) : null,
              active: (resp[1].data.map(type => type.id)).includes(type.id)
            }
          })
          this.outputTypes = resp[2].data[0].active_outputs
          this.loading.ruleTypes = false
          this.loadingSpinner = false
        })
        .catch(err => console.error(err))
    },
    formatLogicOperator(operator) {
      switch (operator) {
      case '<=':
        return { id: 1,  code: operator, text: 'Menor igual que'}
      case '=>':
        return { id: 2,  code: operator, text: 'Mayor igual que'}
      case '==':
        return { id: 3,  code: operator, text: 'Igual a'}
      case '!=':
        return { id: 4,  code: operator, text: 'Diferente a'}
      case 'between':
        return { id: 5,  code: operator, text: 'Entre'}
      }
    },
    activateRule () {
      this.loadingSpinner = true
      const data = { 
        name: `update${this.advanceRuleService}`,
        params: {
          id: this.rule_id,
          [this.entity]: this.entity_id
        },
        queryParams: {
          active: this.form.active,
          name: this.form.name
        },
        onSuccess: (resp) => { this.loadingSpinner = false },
        onError: () => this.loadingSpinner = false
      }
      this.$store.dispatch('fetchService', data)
    },
    editableRule () {
      if (!this.form.id) {
        return false
      } else return this.form.owner?.canEdit
    },
    capitalized (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
  }
}
</script>
<style lang="scss" scope>
.custom-switch-success {
  > label {
    font-size: 14px;
  }
}
.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40000
}
.disable-switch {
  > label::before {
    cursor: not-allowed !important;
  }
  > label::after {
    cursor: not-allowed !important;
  }
}
@media (max-width: 580px) {
  #line0 {
    display: none;
  }
}
  
</style>